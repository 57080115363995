<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      :search="search"
      :loading="tableLoading"
      :server-items-length="data_table_options.totalItems"
      :page="data_table_options.page"
      :options="data_table_options"
      :footer-props="data_table_options.footerProps"
      mobile-breakpoint="960"
      hide-default-footer
      @update:options="(item) => handlePage(item)"
      @click:row="(item) => $emit('edititem', item)"
    >
      <template v-slot:[`item.money`]="{ item }">
        <span>
          <v-chip dark class="my-0" :color="item.in_out == 2 ? 'red' : 'blue'">
            {{
              item.in_out == 2
                ? $vuetify.lang.t("$vuetify.expense")
                : $vuetify.lang.t("$vuetify.income")
            }}
            {{ "$" + formatNumber(item.money) }}
          </v-chip>
        </span>
      </template>

      <template v-slot:[`item.gen_time`]="{ item }">
        {{ item.gen_time ? item.gen_time.substr(0, 10) : "" }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('edititem', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('del')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('deleteitem', item)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="permissionCheck('add')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('copyitem', item)"
              color="blue"
              v-bind="attrs"
              v-on="on"
              class="mx-2"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.copy") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="$emit('historyitem', item)"
              color="black"
              v-bind="attrs"
              v-on="on"
            >
              mdi-history
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.history") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        {{ checkStrLength(item.note) }}
        <span class="blue--text" v-if="item.note.length > 30">.....more</span>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ checkStrLength(item.address) }}
        <span class="blue--text" v-if="item.address.length > 30"
          >.....more
        </span>
      </template>
    </v-data-table>

    <div class="text-center mt-4">
      <v-row>
        <v-spacer />

        <v-col cols="4" class="mt-2">
          <v-pagination
            v-model="data_table_options.page"
            :length="data_table_options.totalItems"
          />
        </v-col>

        <v-spacer />

        <v-col cols="1" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="data_table_options.totalItems"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="2">
          <v-text-field
            min="1"
            label="Jump to page"
            type="number"
            hint="Press Enter or Unfocus to Confirm"
            @focus="$event.target.select()"
            @change="handleGo2Page"
          />
        </v-col>
      </v-row>
    </div>

    <div class="px-2" style="margin-top: -40px">
      <v-chip
        dark
        class="my-0 mr-4 display-1 pa-5"
        color="blue"
        v-if="getTotalIncome != 0"
      >
        {{ $vuetify.lang.t("$vuetify.income") }}: $
        {{ formatNumber(this.getTotalIncome) }}
      </v-chip>
      <v-chip
        dark
        class="my-0 display-1 pa-5"
        color="red"
        v-if="getTotalExpend != 0"
      >
        {{ $vuetify.lang.t("$vuetify.expense") }}: $
        {{ formatNumber(this.getTotalExpend) }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpenseTable",
  props: {
    headers: Array,
    datas: Array,
    obj: Object,
    search: String,
    tableLoading: Boolean,
    data_table_options: Object,
  },
  computed: {
    getTotalIncome() {
      var totalIncome = 0;
      for (var i = 0; i < this.datas.length; i++) {
        if (this.datas[i].in_out == 1) {
          totalIncome += this.datas[i].money;
        }
      }
      return totalIncome.toFixed(2);
    },
    getTotalExpend() {
      var totalExpend = 0;
      for (var i = 0; i < this.datas.length; i++) {
        if (this.datas[i].in_out == 2) {
          totalExpend += this.datas[i].money;
        }
      }
      return totalExpend.toFixed(2);
    },
  },
  methods: {
    handlePage(item) {
      this.$emit("pageChange", item);
    },
    handleGo2Page(item) {
      this.$emit("go2Page", Number(item));
    },
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
};
</script>

<style></style>
