var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.datas,"search":_vm.search,"loading":_vm.tableLoading,"server-items-length":_vm.data_table_options.totalItems,"page":_vm.data_table_options.page,"options":_vm.data_table_options,"footer-props":_vm.data_table_options.footerProps,"mobile-breakpoint":"960","hide-default-footer":""},on:{"update:options":function (item) { return _vm.handlePage(item); },"click:row":function (item) { return _vm.$emit('edititem', item); }},scopedSlots:_vm._u([{key:"item.money",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-chip',{staticClass:"my-0",attrs:{"dark":"","color":item.in_out == 2 ? 'red' : 'blue'}},[_vm._v(" "+_vm._s(item.in_out == 2 ? _vm.$vuetify.lang.t("$vuetify.expense") : _vm.$vuetify.lang.t("$vuetify.income"))+" "+_vm._s("$" + _vm.formatNumber(item.money))+" ")])],1)]}},{key:"item.gen_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gen_time ? item.gen_time.substr(0, 10) : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edititem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])]),(_vm.permissionCheck('del'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])]):_vm._e(),(_vm.permissionCheck('add'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('copyitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.copy")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"black"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('historyitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-history ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.history")))])])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more ")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('v-pagination',{attrs:{"length":_vm.data_table_options.totalItems},model:{value:(_vm.data_table_options.page),callback:function ($$v) {_vm.$set(_vm.data_table_options, "page", $$v)},expression:"data_table_options.page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.data_table_options.totalItems,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number","hint":"Press Enter or Unfocus to Confirm"},on:{"focus":function($event){return $event.target.select()},"change":_vm.handleGo2Page}})],1)],1)],1),_c('div',{staticClass:"px-2",staticStyle:{"margin-top":"-40px"}},[(_vm.getTotalIncome != 0)?_c('v-chip',{staticClass:"my-0 mr-4 display-1 pa-5",attrs:{"dark":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.income"))+": $ "+_vm._s(_vm.formatNumber(this.getTotalIncome))+" ")]):_vm._e(),(_vm.getTotalExpend != 0)?_c('v-chip',{staticClass:"my-0 display-1 pa-5",attrs:{"dark":"","color":"red"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.expense"))+": $ "+_vm._s(_vm.formatNumber(this.getTotalExpend))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }